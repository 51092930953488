body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
  font-weight: 600;
  margin-bottom: 7px;
  font-size: 17px;
}

Button {
  text-transform: capitalize !important;
}

.cancel_btn {
  color: black !important;
  box-shadow: 0px 0px 3px black;
  border: none;
  background: white;
  border-radius: 4px;
}

.btn_primary {
  text-decoration: none !important;
  background:#4539ca!important;
}

.btn_primary:hover {
  text-decoration: none !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {

  background: #015249;
}

.relative{
  position: relative;
}

.custom-btn{
  background: #534ba8!important;
  color: #ffffff!important;
  border: 1px solid #534ba8!important;
  border-radius: 5px;
}

.bg-main{
  background: #534ba8;
}

.over-flow-hide-x{
  overflow-x: hidden;
}

.pointer{
  cursor: pointer;
}

.round-border{
  border-radius: 100%;
}

.text-gray{
  color: gray;
}
.img-btn{
  background: #f1f1f1!important;
  color: white!important;
  padding: 15px 8px!important;
}
.img-style{
  width: 100%;
  height: 91px;
  border-radius:5px ;
}

/* sagment style */

.close-btn-position{
  position: absolute;
  right: 2px;
  cursor: pointer;
}

/* Brand style */

.select-style{
  height: 38px;
}
.select-style1{
  height: 50px;
  /* padding-top: -5px; */
  /* padding-bottom: 5px; */
}


/* Model Style */

.w-5{
  width: 5%;
}
.w-12{
  width: 35px;
}

.text_cap{
  text-transform: capitalize!important;
}

/* upload Icon box style */

.box_style{
  width:100px!important;
  height:100px!important;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root{
overflow-y: hidden !important;
}
.customSelect {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
}

.customSelect:focus {
  border-color: #007bff;
}

.viewIcon {
  cursor: pointer !important;
}

.viewIcon:hover {
  color: #534ba8;
}

.tableRows {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.tableText {
  vertical-align: sub !important;
}

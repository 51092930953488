.sidebar_main_con {
    width: 230px;
    transition: all 0.3s ease-out;
    background-color: #534ba8;
color: #fdfdfd;
}

.top_bar {
    height: 8vh;
    background-color:#534ba8;
    color: #fdfdfd;
    width:100%;
}

.sidebar_item_con {
    margin-top: 15px;

}

.child_con {
    height: 92vh;
    width:100%;
    overflow-y: scroll;
}

